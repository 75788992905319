import { useCareproviderAccountAssociation } from "apollo/hooks/mutations";
import {
  getLoginArguments,
  getTentativeLoginAccountId,
  LoginFnError,
  useLogin,
} from "apollo/mutations/login";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "core/consts";
import { useSafeState } from "core/hooks";
import auth from "core/model/api/endpoints/auth";
import { getErrorMessage } from "core/model/utils/errors";
import { getQueryVariable } from "core/model/utils/urls";
import { QueryProgress, TrackEventFn } from "core/types";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useOnboarding } from "dsl/ecosystems/CareproviderOnboarding";
import { usePreventUnload } from "dsl/hooks";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { validateEmail } from "react-forms-state";
import { useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { getLoginError, getLoginFailureReason } from ".";

export type LoginModule = "login" | "challenge" | "passwordReset";

export async function handleLoginError({
  challenge,
  email,
  error,
  setError,
  setModule,
  setProgressLogin,
  token,
  trackEvent,
  translations,
}: {
  challenge: string | undefined;
  email: string;
  error: LoginFnError;
  setError: Dispatch<SetStateAction<string | undefined>>;
  setModule: Dispatch<SetStateAction<LoginModule>>;
  setProgressLogin: Dispatch<SetStateAction<QueryProgress>>;
  token: string | undefined;
  trackEvent: TrackEventFn;
  translations: Translations;
}) {
  const {
    needsChallenge,
    remaining_time = 0,
    status,
  } = getLoginError(error as LoginFnError);

  if (needsChallenge) {
    setModule("challenge");
    setProgressLogin(QUERY_PROGRESS_NOT_STARTED);
    return;
  }

  const rawError = getErrorMessage(error);
  const reason = getLoginFailureReason({
    challenge,
    rawError,
    remaining_time,
    status,
    token,
    translations,
  });

  trackEvent({
    name: TRACK_EVENTS.LOGIN_ATTEMPT_FAILED,
    reason,
    error_message: rawError,
    account_id: getTentativeLoginAccountId(),
    email,
  });

  if (reason) {
    setError(reason);
  } else {
    throw new Error("No reason for failed login");
  }

  setProgressLogin(QUERY_PROGRESS_FAILED);
}

export function useLoginPage({
  careproviderToken,
  careproviderToLog,
  isOnboardingFlow,
}: {
  careproviderToLog?: number;
  careproviderToken?: string;
  isOnboardingFlow?: boolean;
} = {}) {
  const login = useLogin();
  const { next } = useOnboarding();
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const toast = useToast();
  const [error, setError] = useState<string>();
  const [module, setModule] = useState<LoginModule>("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oauthToken, setOAuthToken] = useState<string | undefined>();
  const [challenge, setChallenge] = useState<string | undefined>();
  const [status, setStatus] = useState("");
  const [associate] = useCareproviderAccountAssociation({
    id: careproviderToLog ?? -1,
    token: careproviderToken,
  });
  const [submitData, setDidSubmitData] = useState<{
    didSubmit: boolean;
  }>({
    didSubmit: false,
  });
  const [loginProgress, setProgressLogin] = useSafeState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const { token } = useParams();

  usePreventUnload(loginProgress, password == "");

  const onLogin = useCallback(() => {
    setDidSubmitData({ didSubmit: true });
  }, []);

  useEffect(() => {
    setStatus("login");
  }, [loginProgress]);

  useEffect(() => {
    setProgressLogin(QUERY_PROGRESS_NOT_STARTED);
    setChallenge(undefined);
    setError(undefined);
  }, [module]);

  useEffect(() => {
    const verifiedDevice = getQueryVariable(
      window.location.search,
      "verified_device",
    );
    if (verifiedDevice === "false") {
      setError(translations.login.mfa.invalidToken);
    } else if (verifiedDevice === "true") {
      toast({
        message: translations.login.mfa.deviceVerified,
        color: "primary",
      });
    }

    const logoutReason = sessionStorage.getItem("logout_reason");
    if (logoutReason === "reset_identity") {
      toast({
        message: translations.general.loginPostIdentityReset,
        color: "primary",
      });
    }
    sessionStorage.removeItem("logout_reason");
  }, []);

  useEffect(() => {
    if (validateEmail({ email, required: true }) === true || oauthToken) {
      auth
        .oauthCheck({ email })
        .then((res) => {
          if (res?.url) setOAuthToken(res.url);
          else throw new Error();
        })
        .catch((_) => {
          setOAuthToken(undefined);
        });
    }
  }, [email]); // only sync on email changes

  useLayoutEffect(() => {
    async function processLogin() {
      setProgressLogin(QUERY_PROGRESS_PENDING);
      if (oauthToken) {
        window.location.href = oauthToken;
        return;
      }
      try {
        const loginArgs = await getLoginArguments({
          username: email,
          password,
          challenge,
          careproviderToLog,
          token,
        });
        await login(loginArgs);
        setProgressLogin(QUERY_PROGRESS_SUCCEED);

        if (isOnboardingFlow && careproviderToLog) {
          await associate({ email });
          next();
        }
      } catch (err) {
        handleLoginError({
          error: err as LoginFnError,
          challenge,
          setProgressLogin,
          setModule,
          token,
          trackEvent,
          email,
          translations,
          setError,
        });
      }
    }

    if (submitData?.didSubmit) {
      processLogin();
    }
  }, [submitData, setError]);

  return {
    challenge,
    email,
    error,
    loginProgress,
    module,
    oauthToken,
    onLogin,
    password,
    setChallenge,
    setEmail,
    setModule,
    setPassword,
    status,
  };
}
