import SvgIcon from "@mui/material/SvgIcon";
import { disabledFlagStyle } from "./index";

export default function FrFlag({ active }: { active?: boolean }) {
  return (
    <SvgIcon
      titleAccess="flag-fr"
      viewBox="0 0 640 480"
      style={!active ? disabledFlagStyle : {}}
      aria-hidden
      role="presentation"
    >
      <rect width="640" height="480" fill="#ED2939" />
      <rect width="426" height="480" fill="#fff" />
      <rect width="213" height="480" fill="#002395" />
    </SvgIcon>
  );
}
